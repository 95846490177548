import axios from 'axios';
import {loadingController} from '@ionic/vue';
import {TokenService} from './token';

const ApiService = {
  _requestInterceptor: 0,
  _401Interceptor: 0,

  init(baseURL) {
    axios.defaults.baseURL = baseURL;
  },

  setHeader() {
    axios.defaults.headers.common['Authorization'] = `Bearer ${TokenService.getToken()}`;
  },

  removeHeader() {
    axios.defaults.headers.common = {};
  },

  get(url, payload) {
    return axios.get(url, payload);
  },

  post(url, payload) {
    return axios.post(url, payload);
  },

  put(url, payload) {
    return axios.put(url, payload);
  },

  delete(url, payload) {
    return axios.delete(url, payload);
  },

  customRequest(request, payload) {
    return axios(request, payload);
  },

  mountRequestInterceptor() {
    this._requestInterceptor = axios.interceptors.request.use(async config => {
      const loading = await loadingController.create({
        message: 'Please wait...'
      });
      await loading.present();

      return config;
    });
  },

  mount401Interceptor() {
    this._401interceptor = axios.interceptors.response.use(
        response => {
          loadingController.dismiss().then(r => console.log(r));
          return response;
        },
        async error => {
          loadingController.dismiss().then(r => console.log(r));
          if (error.request.status === 401) {
            if (error.config.url.includes('oauth/token')) {
              // await store.dispatch("auth/signOut");
              throw error;
            } else {
              try {
                // await store.dispatch("auth/refreshToken");
                return this.customRequest({
                  method: error.config.method,
                  url: error.config.url,
                  data: error.config.data
                });
              } catch (e) {
                throw error;
              }
            }
          }
          throw error;
        }
    );
  },

  unmount401Interceptor() {
    axios.interceptors.response.eject(this._401interceptor);
  }
};

export default ApiService;
