<template>
  <template v-if="isLoading">
    <ion-card>
      <ion-card-header>
        <div>
          <ion-card-subtitle>
            <ion-skeleton-text animated style="width: 100px"/>
          </ion-card-subtitle>
          <ion-card-title>
            <ion-skeleton-text animated style="width: 200px"/>
          </ion-card-title>
        </div>
        <div>
          <ion-skeleton-text animated style="width: 50px"/>
        </div>
      </ion-card-header>
      <ion-item lines="none">
        <ion-skeleton-text animated style="width: 24px;height: 24px; margin-right: 30px;"/>
        <ion-label>
          <ion-skeleton-text animated style="width: 90px;display: block;"/>
          <ion-skeleton-text animated style="width: 100px;"/>
        </ion-label>
      </ion-item>
      <ion-item lines="full" style="--min-height: 14px;">
        <small style="padding-bottom: 16px;">
          <ion-skeleton-text animated style="width: 100px;"/>
        </small>
      </ion-item>
      <ion-card-content>
        <div>
          <ion-skeleton-text animated style="width: 80px;"/>
          <ion-skeleton-text animated style="width: 100px"/>
        </div>
        <div>
          <ion-skeleton-text animated style="width: 100px;"/>
        </div>
      </ion-card-content>
    </ion-card>
  </template>
  <template v-if="!isLoading">
    <ion-card button="true" href="/" class="ion-margin-bottom">
      <ion-card-header>
        <div>
          <ion-card-subtitle style="font-size: 12px;">
            {{ batch.formatted_created_at }}
          </ion-card-subtitle>
          <ion-card-title style="font-size: 16px;">
            {{ batch.serial_number }}
          </ion-card-title>
        </div>
        <div>
          <ion-badge color="success" style="font-size: 10px;text-align: right;">
            TODO
          </ion-badge>
        </div>
      </ion-card-header>
      <ion-item lines="none">
        <ion-icon :icon="flask" slot="start"></ion-icon>
        <ion-label style="font-size: 14px;">
          <strong style="display: block;">89095874</strong>
          923 - maulana
        </ion-label>
      </ion-item>
      <ion-item lines="none" style="--min-height: 14px;" v-if="batch.samples.length > 1">
        <small style="padding-bottom: 16px;">
          + {{batch.samples.length - 1}} sampel lainnya
        </small>
      </ion-item>
      <ion-card-content>
        <div>
          <span>Total Tagihan</span><br>
          <strong class="ion-color-dark">
            {{ batch.formatted_total_purchase }}
          </strong>
        </div>
        <div>
          <ion-button size="small" style="text-transform: none;">
            Lihat Detail
          </ion-button>
        </div>
      </ion-card-content>
    </ion-card>
  </template>
</template>

<style lang="scss" scoped>
ion-card-header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, .13);
}

ion-card-content {
  border-top: 1px solid rgba(0, 0, 0, .13);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>

<script>
import {defineComponent} from 'vue';
import {
  IonBadge,
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonIcon,
  IonItem,
  IonLabel,
  IonSkeletonText
} from '@ionic/vue';
import {flask} from 'ionicons/icons';

export default defineComponent({
  name: 'FPC Card',
  props: [
    'batch',
    'isLoading'
  ],
  components: {
    IonCard,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonBadge,
    IonIcon,
    IonItem,
    IonLabel,
    IonCardContent,
    IonButton,
    IonSkeletonText
  },
  setup() {
    return {
      flask
    };
  }
});
</script>
