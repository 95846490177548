<template>
  <ion-page style="padding-bottom: 24px;">
    <ion-header>
      <ion-toolbar color="primary">
        <ion-title>{{pageTitle}}</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">
            {{pageTitle}}
          </ion-title>
        </ion-toolbar>
      </ion-header>
      <slot/>
    </ion-content>
  </ion-page>
</template>

<script>
import {IonContent, IonHeader, IonPage, IonTitle, IonToolbar} from '@ionic/vue';

export default {
  components: {
    IonPage, IonHeader, IonToolbar, IonTitle, IonContent
  },
  props: [
      'pageTitle'
  ]
};
</script>
