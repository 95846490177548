<template>
  <ion-page>
    <ion-tabs>
      <ion-router-outlet></ion-router-outlet>
      <ion-tab-bar slot="bottom">
        <ion-tab-button tab="dashboard" href="/dashboard">
          <ion-icon :icon="home"/>
          <ion-label>Home</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="fpc" href="/fpc" style="margin-right: 30px;">
          <ion-icon :icon="document"/>
          <ion-label>FPC</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="profile" href="/login" style="margin-left: 30px;">
          <ion-icon :icon="personCircle"/>
          <ion-label>Login</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="profile" @click.prevent="handleLogout()">
          <ion-icon :icon="personCircle"/>
          <ion-label>Logout</ion-label>
        </ion-tab-button>
      </ion-tab-bar>
      <ion-fab vertical="bottom" horizontal="center" edge slot="fixed">
        <ion-fab-button style="--box-shadow: none">
          <ion-icon :icon="add"></ion-icon>
        </ion-fab-button>
      </ion-fab>
    </ion-tabs>
  </ion-page>
</template>

<script>
import {
  IonFab,
  IonFabButton,
  IonIcon,
  IonLabel,
  IonPage,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs
} from '@ionic/vue';
import {add, document, home, personCircle} from 'ionicons/icons';
import {AuthService} from '../services/auth';
import {useRouter} from 'vue-router';

export default {
  data: () => {
    return {
      home,
      document,
      personCircle,
      add
    };
  },
  components: {
    IonTabs,
    IonRouterOutlet,
    IonTabBar,
    IonIcon,
    IonLabel,
    IonTabButton,
    IonFabButton,
    IonFab,
    IonPage
  },
  setup() {
    const router = useRouter();
    const handleLogout = () => {
      AuthService.logout();
      router.push('/login');
    };

    return {
      handleLogout
    };
  }
};
</script>

<style lang="scss" scoped>
ion-fab-button {
  --background: var(--ion-color-primary);
  bottom: 40px;
  position: relative;
  transform: rotate(45deg);
  --border-radius: 15px;

  ion-icon {
    transform: rotate(-45deg);
  }
}
</style>
