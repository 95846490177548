<template>
  <base-layout pageTitle="FPC">
    <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
      <ion-refresher-content></ion-refresher-content>
    </ion-refresher>
    <FpcCard v-for="batch in batches" :key="batch.id" :batch="batch" :is-loading="isLoading"/>
  </base-layout>
</template>

<script>
import {defineComponent, ref} from 'vue';
import {IonRefresher, IonRefresherContent, onIonViewDidEnter} from '@ionic/vue';
import FpcCard from '../../components/FpcCard';
import api from '../../services/api';

export default defineComponent({
  name: 'FPC Index',
  components: {
    IonRefresher,
    IonRefresherContent,
    FpcCard
  },
  setup() {
    const batches = ref(Array(10).fill([]));
    const isLoading = ref(true);
    const fetchBatches = async () => {
      isLoading.value = true;
      const result = await api.get('/batch', {
        params: {
          per_page: 10,
          sortby: 'created_at',
          sortbydesc: 'asc'
        }
      });

      batches.value = result.data.data.result;
      isLoading.value = false;
    };

    const doRefresh = async event => {
      await fetchBatches();
      event.target.complete();
    };

    onIonViewDidEnter(() => {
      fetchBatches();
    });

    return {
      isLoading,
      batches,
      doRefresh
    };
  }
});
</script>
