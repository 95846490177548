import {createRouter, createWebHistory} from '@ionic/vue-router';
import HomePage from '@/pages/HomePage.vue';
import {TokenService} from '../services/token';
import Fpc from '../pages/home/Fpc';
import Dashboard from '../pages/home/Dashboard';
import Profile from '../pages/home/Profile';

const routes = [
  {
    path: '/',
    redirect: '/dashboard'
  },
  {
    path: '/dashboard',
    component: HomePage,
    children: [
      {
        path: '',
        component: Dashboard,
        name: 'dashboard'
      },
      {
        path: '/fpc',
        component: Fpc,
        name: 'fpc'
      },
      {
        path: '/profile',
        component: Profile,
        name: 'profile'
      }
    ]
  },
  {
    path: '/login',
    component: () => import('@/pages/Login.vue'),
    meta: {
      public: true,
      onlyWhenLoggedOut: true
    }
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  const isPublic = to.matched.some(record => record.meta.public);
  const onlyWhenLoggedOut = to.matched.some(
      record => record.meta.onlyWhenLoggedOut
  );
  const loggedIn = !!TokenService.getToken();

  if (!isPublic && !loggedIn) {
    return next({
      path: '/login',
      query: {redirect: to.fullPath}
    });
  }

  if (loggedIn && onlyWhenLoggedOut) {
    return next('/dashboard');
  }

  next();
});

export default router;
